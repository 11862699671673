import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { MDXLayout as PageLayout } from "../components/blocks/mdx-layout";
import { SEO } from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Setup" description="This document contains my current productivity setup" breadcrumbListItems={[{
      name: `Setup`,
      url: `/setup`
    }]} mdxType="SEO" />

    <h1 {...{
      "id": "setup"
    }}>{`Setup`}</h1>
    <p>{`This document 📄 contains my 🔒 current 💰 productivity setup for 😘 anyone 🙋 interested. 😙😙🙈`}</p>
    <h2 {...{
      "id": "editor--terminal"
    }}>{`Editor & Terminal`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://code.visualstudio.com/"
        }}>{`Visual Studio Code`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/microsoft/terminal"
        }}>{`Windows Terminal`}</a></li>
    </ul>
    <h2 {...{
      "id": "hardware"
    }}>{`Hardware`}</h2>
    <ul>
      <li parentName="ul"><em parentName="li">{`PC Specs`}</em>{`: `}<a parentName="li" {...{
          "href": "/wiki/building-a-custom-pc"
        }}>{`RTX 3080 + Ryzen 5600x`}</a></li>
      <li parentName="ul"><em parentName="li">{`Keyboard`}</em>{`: GMMK Pro with Cherry MX Blacks (Krytox 205g0 + 60g SPRiT springs + Deskeys films) and GMK Olivia`}</li>
      <li parentName="ul"><em parentName="li">{`Mouse`}</em>{`: G Pro X Superlight`}</li>
    </ul>
    <hr></hr>
    {
      /* To learn what this page looked like in the past, checkout [the file history on GitHub](https://github.com/tplai/tlai-io/commits/main/www/src/pages/setup.mdx). */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      